import { useHistory, useRouteMatch } from "react-router";
import { Alert, Box, Button, CircularProgress, Container, Divider, Stack, Typography } from "@mui/material";

import { CopyCodeBlock } from "../../../Components/CopyCodeBlock/CopyCodeBlock";
import { useCustomerContext } from "../../../Context/CustomerContext";
import SelectedAccountAndCluster from "../ClusterActivation/Steps/components/SelectedAccountAndCluster";
import { useCluster } from "../hooks";

const updateChartCommand = `
helm repo update perfectscale
`.trim();

const upgradeAgentCommand = `
helm upgrade --install \\
  -n perfectscale \\
  --reset-then-reuse-values \\
  perfectscale perfectscale/exporter
`.trim();

const ClusterAgentUpgrade = () => {
  const { customer } = useCustomerContext();
  const history = useHistory();
  const match = useRouteMatch<{ clusterId: string }>();

  const [cluster, clusterLoading] = useCluster(match.params.clusterId);

  const handleCancel = () => {
    history.push(`/customers/${customer.id}/assets/kubernetes`);
  };

  if (clusterLoading)
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "95vh",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Container sx={{ pt: 4 }}>
      <Box
        sx={{
          mt: 6,
          mb: 4,
          mx: "auto",
          maxWidth: 745,
        }}
      >
        <Stack spacing={4}>
          <Stack spacing={2}>
            <Typography variant="h1">Upgrade Flexsave for Kubernetes agent</Typography>
            {!!cluster && (
              <SelectedAccountAndCluster projectId={cluster.projectId ?? ""} selectedClusters={[cluster]} />
            )}
          </Stack>

          <Alert severity="info">Upgrade to v1.0.56 is now available</Alert>

          <Stack spacing={2}>
            <Typography>1. Update Helm repo</Typography>
            <Stack spacing={1}>
              <Typography variant="body2" color="text.secondary">
                Update the Helm repo to get the latest version of the agent
              </Typography>
              <Typography>
                <CopyCodeBlock base={updateChartCommand} />
              </Typography>
            </Stack>
          </Stack>

          <Stack spacing={2}>
            <Typography>2. Upgrade agent</Typography>
            <Stack spacing={1}>
              <Typography variant="body2" color="text.secondary">
                Upgrade agent to the latest version
              </Typography>
              <Typography>
                <CopyCodeBlock base={upgradeAgentCommand} />
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>

      <Box
        sx={{
          bgcolor: "background.paper",
          position: "fixed",
          bottom: 0,
          width: "100%",
          left: 0,
          zIndex: 20,
        }}
      >
        <Divider sx={{ gridColumn: "1/13" }} />
        <Box
          sx={{
            mx: "auto",
            maxWidth: 745,
            display: "flex",
            py: 1,
            margin: "auto",
            justifyContent: "flex-end",
          }}
        >
          <Stack spacing={2} direction="row">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button variant="contained" onClick={handleCancel}>
              Done
            </Button>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
};

export default ClusterAgentUpgrade;
