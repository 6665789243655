import { ClusterType, type ClusterWithCloudConnect } from "@doitintl/cmp-models";
import { Stack } from "@mui/material";
import uniqBy from "lodash/uniqBy";

import { isCloudConnectHealthy } from "../utils";
import ClusterConnectionIssueAlert from "./ClusterConnectionIssueAlert";

interface ClusterTableAlertsProps {
  clusters: ClusterWithCloudConnect[];
  loading?: boolean;
}

const ClusterTableAlerts = ({ clusters, loading }: ClusterTableAlertsProps) => {
  const clustersWithConnectionIssues = clusters.filter(
    (cluster) => cluster.type !== ClusterType.SELF_MANAGED && !isCloudConnectHealthy(cluster.cloudConnect)
  );

  // If we have multiple clusters with issues for the same cloud connect doc, we take only one
  const uniqueClustersByProjectId = uniqBy(clustersWithConnectionIssues, "projectId");

  if (loading || uniqueClustersByProjectId.length === 0) {
    return null;
  }

  return (
    <Stack spacing={1} sx={{ maxHeight: 300, overflowY: "auto" }}>
      {uniqueClustersByProjectId.map((cluster) => (
        <ClusterConnectionIssueAlert key={cluster.projectId} cluster={cluster} />
      ))}
    </Stack>
  );
};

export default ClusterTableAlerts;
