import {
  CloudAnalyticsModel,
  type CloudAnalyticsReportReadyNotificationModel,
  InAppNotificationType,
} from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { Typography } from "@mui/material";

import { type Mapper } from "../types";

export class ReportReady implements Mapper<CloudAnalyticsReportReadyNotificationModel> {
  readonly type = InAppNotificationType.ReportReady;

  getCollection: Mapper<CloudAnalyticsReportReadyNotificationModel>["getCollection"] = (
    customerRef,
    startTime,
    userEmail
  ) =>
    getCollection(CloudAnalyticsModel)
      .doc("reports")
      .collection("cloudAnalyticsReportReadyNotifications")
      .where("created", ">=", startTime)
      .where("customer", "==", customerRef)
      .where("recipient", "==", userEmail);

  userHasPermission: Mapper<CloudAnalyticsReportReadyNotificationModel>["userHasPermission"] = (userRoles) =>
    userRoles?.cloudAnalyticsUser ?? false;

  toNotification: Mapper<CloudAnalyticsReportReadyNotificationModel>["toNotification"] = (docSnap) => {
    const docData = docSnap.asModelData();
    const reportName = docData.reportName;

    if (!reportName) {
      return null;
    }

    const description = (
      <Typography
        variant="body2"
        sx={{
          color: "text.secondary",
        }}
      >
        {docData.failed
          ? `"${reportName}" failed to cache.`
          : `Your cached report for "${reportName}" is ready to view.`}
      </Typography>
    );

    return {
      id: docSnap.id,
      ref: docSnap.modelRef,
      type: InAppNotificationType.ReportReady,
      created: docData.created,
      updated: undefined,
      title: docData.failed ? "Report update" : "Cached report is ready",
      description,
      urlPath: `/customers/${docData.customer.id}/analytics/reports/${docData.reportId}`,
      color: "#A9D9ED",
    };
  };
}
