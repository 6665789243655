import ClusterPageHeader from "./Header";
import { ClusterTable } from "./Table";
import ClusterTableAlerts from "./Table/Alerts/ClusterTableAlerts";
import { useClusterTableItems } from "./Table/hooks";

const ClustersPage = () => {
  const [clusters, clustersLoading] = useClusterTableItems();

  return (
    <>
      <ClusterTableAlerts clusters={clusters} loading={clustersLoading} />
      <ClusterPageHeader />
      <ClusterTable clusters={clusters} loading={clustersLoading} />
    </>
  );
};

export default ClustersPage;
