import { useState } from "react";

import { useHistory } from "react-router";
import { ClusterType } from "@doitintl/cmp-models";
import { Skeleton, Stack } from "@mui/material";

import { FilterTable } from "../../../../Components/FilterTable/FilterTable";
import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../../Components/FilterTable/filterTableUtils";
import { type FilterTableToolbarProps } from "../../../../Components/FilterTable/Toolbar/FilterTableToolbar";
import useRouteMatchURL from "../../../../Components/hooks/useRouteMatchURL";
import { ClusterDialog } from "../ClusterDialog";
import ClusterTableRow from "./ClusterRow";
import { type ClusterTableItem } from "./types";
import { sortedValueToOption } from "./utils";

interface ClusterTableProps {
  clusters: ClusterTableItem[];
  loading: boolean;
}

const ClusterTable = ({ clusters, loading }: ClusterTableProps) => {
  const history = useHistory();
  const routeMatchURL = useRouteMatchURL();

  const [clusterDialogIsOpen, setClusterDialogIsOpen] = useState(false);

  const openClusterDialog = () => {
    setClusterDialogIsOpen(true);
  };

  const getProjectIdHeaderText = () => {
    const hasGkeClusters = clusters.some((cluster) => cluster.type === ClusterType.GKE);
    const hasEksClusters = clusters.some((cluster) => cluster.type === ClusterType.EKS);

    if (hasGkeClusters && !hasEksClusters) return "Project";
    if (hasEksClusters && !hasGkeClusters) return "Account";
    return "Account/Project";
  };

  const itemDescriptions: FilterTableItemDescriptor<ClusterTableItem>[] = [
    {
      headerText: "Name",
      value: "name",
      filterType: "string",
    },
    {
      headerText: "Status",
      value: "computedStatus",
      filterType: "string",
      filterOptions: {
        toOption: sortedValueToOption,
      },
    },
    {
      headerText: "Type",
      value: "type",
      filterType: "string",
    },
    {
      headerText: "Enabled features",
      value: "enabledFeatures",
      filterType: "string",
    },
    {
      headerText: "Agent status",
      value: "agentStatus",
      filterType: "string",
      filterOptions: {
        toOption: sortedValueToOption,
      },
    },
    {
      headerText: "Region",
      value: "region",
      filterType: "string",
    },
    {
      headerText: getProjectIdHeaderText(),
      value: "projectId",
      filterType: "string",
    },
    {
      headerText: "Version",
      value: "kubernetesControlPlaneVersion",
      filterType: "string",
    },
    {
      value: "@",
      headerText: "",
      headerStyle: {
        width: "16px",
        align: "right",
      },
    },
  ];

  const { headerColumns, filters } = generateHeadersAndFilters(itemDescriptions);

  const toolbarProps: FilterTableToolbarProps = {
    allowToEditColumns: true,
    primaryButton: {
      onClick: openClusterDialog,
      text: "Connect self-managed cluster",
      "data-cy": "connectSelfManagedClusterButton",
    },
    secondaryButtons: [
      {
        onClick: () => {
          history.push(`${routeMatchURL}/activate/bulk`);
        },
        text: "Enable features",
        "data-cy": "enableFeaturesButton",
      },
    ],
  };

  if (loading) {
    return <Skeleton variant="rectangular" height={400} />;
  }

  return (
    <Stack>
      <ClusterDialog open={clusterDialogIsOpen} setOpen={setClusterDialogIsOpen} />
      <FilterTable<ClusterTableItem>
        rowComponent={ClusterTableRow}
        persistenceKey="k8s-clusters-table"
        toolbarProps={toolbarProps}
        tableItems={clusters}
        headerColumns={headerColumns}
        filterColumns={filters}
        defaultSortingColumnValue="computedStatus"
        defaultSortDirection="asc"
      />
    </Stack>
  );
};

export default ClusterTable;
