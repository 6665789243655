import { useCallback, useEffect, useState } from "react";

import { useHistory, useRouteMatch } from "react-router";
import { Box, Button, CircularProgress, Container, Divider, Skeleton, Stack, Typography } from "@mui/material";

import { useApiContext } from "../../../api/context";
import { CopyCodeBlock } from "../../../Components/CopyCodeBlock/CopyCodeBlock";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../utils";
import { useErrorSnackbar } from "../../Integrations/Slack/utils";
import { getAgentInstallationCommand } from "../api";
import SelectedAccountAndCluster from "../ClusterActivation/Steps/components/SelectedAccountAndCluster";
import { useCluster } from "../hooks";

const cloneChartCommand = `
helm repo add perfectscale https://perfectscale-io.github.io --force-update
`.trim();

const ClusterAgentInstallation = () => {
  const api = useApiContext();
  const { customer } = useCustomerContext();
  const history = useHistory();
  const match = useRouteMatch<{ clusterId: string }>();
  const errorSnackbar = useErrorSnackbar();

  const [helmCommand, setHelmCommand] = useState();
  const [helmCommandLoading, setHelmCommandLoading] = useState(true);
  const [cluster, clusterLoading] = useCluster(match.params.clusterId);

  const fetchHelmCommand = useCallback(async () => {
    if (!cluster?.id || helmCommand) return;

    setHelmCommandLoading(true);
    try {
      const response = await getAgentInstallationCommand(api, customer.id, cluster?.id);
      setHelmCommand(response.data);
    } catch (error) {
      consoleErrorWithSentry(error);
      errorSnackbar("Failed to fetch Helm command");
    } finally {
      setHelmCommandLoading(false);
    }
  }, [api, customer.id, cluster?.id, errorSnackbar, helmCommand]);

  useEffect(() => {
    fetchHelmCommand();
  }, [fetchHelmCommand]);

  const handleCancel = () => {
    history.push(`/customers/${customer.id}/assets/kubernetes`);
  };

  if (clusterLoading)
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "95vh",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Container sx={{ pt: 4 }}>
      <Box
        sx={{
          mt: 6,
          mb: 4,
          mx: "auto",
          maxWidth: 745,
        }}
      >
        <Stack spacing={4}>
          <Stack spacing={2}>
            <Typography variant="h1">Install Flexsave for Kubernetes agent</Typography>
            {!!cluster && (
              <SelectedAccountAndCluster projectId={cluster.projectId ?? ""} selectedClusters={[cluster]} />
            )}
          </Stack>

          <Stack spacing={2}>
            <Typography>
              1. Clone the Helm Chart repository to a location where you have access to your Kubernetes cluster:
            </Typography>
            <Stack spacing={1}>
              <Typography variant="body2" color="text.secondary">
                Clone Helm Chart
              </Typography>
              <Typography>
                <CopyCodeBlock base={cloneChartCommand} />
              </Typography>
            </Stack>
          </Stack>

          <Stack spacing={2}>
            <Typography>2. Deploy the Helm Chart</Typography>
            <Stack spacing={1}>
              <Typography variant="body2" color="text.secondary">
                Deploy Helm Chart
              </Typography>
              <Typography>
                {helmCommandLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={192} />
                ) : (
                  <CopyCodeBlock base={helmCommand ?? ""} />
                )}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>

      <Box
        sx={{
          bgcolor: "background.paper",
          position: "fixed",
          bottom: 0,
          width: "100%",
          left: 0,
          zIndex: 20,
        }}
      >
        <Divider sx={{ gridColumn: "1/13" }} />
        <Box
          sx={{
            mx: "auto",
            maxWidth: 745,
            display: "flex",
            py: 1,
            margin: "auto",
            justifyContent: "flex-end",
          }}
        >
          <Stack spacing={2} direction="row">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button variant="contained" onClick={handleCancel}>
              Done
            </Button>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
};

export default ClusterAgentInstallation;
